.qa-container {
  font-family: sans-serif;
  padding: 10px;
}

.flex-container {
  display: flex;
  justify-content: space-around;
  padding-bottom: 02em;
  padding-top: 2em;
  padding-left: 2em;
  .flex {
    display: flex;
    justify-content: space-between;
  }
}

.logo {
  display: flex;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: rgb(242, 197, 159);
  /* padding-top: 0.5px;
         padding-bottom: 0.5px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  margin: 0;
}

.footer img {
  width: auto;
  height: 15px;
  margin-left: 5px;
  background-color: black;
}

.footer h4 {
  font-size: 14px;
  margin-left: 15px;
  align-items: center;
}
