@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Lexend", sans-serif;
  font-weight: 400;
}

body {
  background-color: #f5f5f5;
}

.login {
  background-color: #d5e9fe;
}

.fs-21 {
  font-size: 21px;
}

.fs-13 {
  font-size: 13px;
}

.btn-blue {
  background-color: #0f4c7d;
  color: #fff;
  border-color: #0f4c7d;
}

.break-word-1 {
  word-wrap: break-word !important;
  max-width: 1px !important;
}

.break-word-2 {
  word-wrap: break-word !important;
  max-width: 2px !important;
}

/* topbar */
.topbar {
  background-color: #0f4c7d;
  color: #fff;
}

/* sidebar */
a.active,
a.active * {
  color: #0f4c7d !important;
  stroke: #0f4c7d;
  font-weight: 500;
}

.sidebar .active button {
  color: #0f4c7d;
  stroke: #0f4c7d;
  font-weight: 500;
}

.angle {
  transition: all 0.3s ease;
}

.sidebar .active .hidden {
  display: block;
  transition: 0.5s;
}

.sidebar .active .angle {
  transform: rotate(-90deg);
}

/* dashboard */
.text-blue {
  color: #0f4c7d;
}

.dash-card {
  border: 2px solid #c8c8c8;
  overflow: hidden;
}

.text-gray-500 {
  color: #111111;
}

/* table */
td.border.w-full.p-2 {
  border: unset;
  border-bottom: 2px solid #e3e3e3;
}

/* productivity */
.productivity {
  background-color: #d5e9fe;
}

/* input focus */
input:focus,
textarea:focus,
select:focus {
  box-shadow: unset !important;
  outline: unset !important;
}

/* tttable */
.tttable td {
  width: 25%;
}

/* create new worksite */
.fs-18 {
  font-size: 18px;
}

/* profile */
.fs-22 {
  font-size: 22px;
}

.fs-10 {
  font-size: 10px;
}

.fs-14 {
  font-size: 14px;
}

/* tab btns */
.bg-transparent {
  background-color: transparent !important;
}

Button span {
  line-height: 1.2;
}

.tab-buttons button {
  overflow: hidden;
}

/* profile modal */
.modal {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 600px;
  max-width: 98%;
  position: relative;
  margin: 0 auto;
}

.modal-scroll {
  position: fixed;
  height: 90%;
  overflow: auto;
}

.switch-bg-color-active {
  background: #1bbc8d !important;
}

.switch-bg-color-in-active {
  background: #d5d6d6 !important;
}

.cell-breakWord {
  word-wrap: break-word !important;
  max-width: 1px !important;
}

.cell-breakWord-2 {
  word-wrap: break-word !important;
  max-width: 2px !important;
}

.circular-image {
  height: 100px !important;
  width: 100px !important;
  border-radius: 50% !important;
}

.rect-image {
  height: 150px !important;
  width: 150px !important;
}

.scroll-display-none::-webkit-scrollbar {
  display: none !important;
}

.select-height-42:where(.css-1fviqcj).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  width: 100% !important;
  height: 42px !important;
  padding: 5px 11px !important;
}

/* .select-height-42:where(
    .css-dev-only-do-not-override-1fviqcj
  ).ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100% !important;
  height: 42px !important;
  padding: 5px 11px !important;
} */

/* edit site manager */
.fs-12 {
  font-size: 12px;
}

/* making fixed height body */
body {
  height: 100vh;
  overflow: hidden;
}

.content-holder {
  height: calc(100vh - 60px);
  overflow-y: scroll;
}

/* single workers accordion */
.ant-collapse-header {
  flex-direction: row-reverse;
}

.ant-collapse-expand-icon {
  background: #fe650c;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-inline-end: 0 !important;
  width: 22px;
}

.anticon {
  position: relative;
}

.ant-collapse-item.border-none {
  border: unset;
  margin: 20px 0;
  /* background: #f5f5f5; */
  box-shadow: unset;
}

.ant-collapse-content.ant-collapse-content-active {
  border-top: 0;
}

.bg-blue-disable:disabled {
  background-color: #2c6696 !important;
}

.h-full-80 {
  height: 80vh !important;
}

.h-full-60 {
  height: 60vh !important;
}

.no-color-disable:disabled {
  background-color: transparent !important;
}

.dropdownIcon {
  transition: all 0.3s ease;
}

.active-dropdownIcon {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}

.ant-select-selector {
  border: none;
}

.ant-select-selector {
  min-width: 300px;
  width: fit-content;
}

.p-2 .ant-card-body {
  padding: 0;
}

.barC {
  height: 500px !important;
}

.pieC {
  height: 300px !important;
}

.weekBar {
  height: 255px !important;
}

.ant-progress-inner {
  width: 2rem !important;
  height: 2rem !important;
  font-size: 10px !important;
}

.multiline {
  height: 500px !important;
}

/* login responsive */

@media screen and (max-width: 1600px) {
  .login.min-h-screen.\32 xl\:p-40.\32 xl\:px-96.xl\:p-40 {
    padding: 0 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1400px) {
  .login__container__left .text-center {
    padding: 25px;
  }
}

@media screen and (max-width: 1300px) {
  .login.min-h-screen.\32 xl\:p-40.\32 xl\:px-96.xl\:p-40 {
    padding: 0 100px;
  }
}

@media screen and (max-width: 1100px) {
  .login.min-h-screen.\32 xl\:p-40.\32 xl\:px-96.xl\:p-40 {
    padding: 0 50px;
  }
}

@media screen and (max-width: 992px) {
  .login.min-h-screen.\32 xl\:p-40.\32 xl\:px-96.xl\:p-40 {
    padding: 50px 150px;
  }

  .login__container {
    flex-direction: column-reverse;
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .login.min-h-screen.\32 xl\:p-40.\32 xl\:px-96.xl\:p-40 {
    padding: 50px;
  }
}

@media screen and (max-width: 600px) {
  .login.min-h-screen.\32 xl\:p-40.\32 xl\:px-96.xl\:p-40 {
    padding: 10px;
  }
}

.agtable td div {
  max-width: 200px !important;
  overflow: hidden !important;
  margin: auto;
}

.agtable .ant-select {
  max-width: 100% !important;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

select {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

.submenu-sidebar .ant-collapse-content {
  background-color: transparent !important;
}

.submenu-sidebar .ant-collapse-content-box {
  padding: 0 10px !important;
  margin-bottom: 20px;
}

.submenu-sidebar .ant-collapse-item-active .ant-collapse-header-text {
  color: #0f4c7d;
  stroke: #0f4c7d;
  font-weight: 500;
}

.submenu-sidebar .ant-collapse-expand-icon {
  background: transparent;
  color: black;
}

.submenu-sidebar {
  background: transparent;
  border-radius: 0;
  border: unset;
}

.submenu-sidebar * {
  border: unset;
  outline: unset;
  box-shadow: unset;
}

.submenu-sidebar .ant-collapse-item {
  border-bottom: transparent;
}

.childmenu-sidebar .ant-collapse-header {
  padding: 2px 16px !important;
}

.childmenu-sidebar .ant-collapse-content-box {
  padding: 0px 5px !important;
}

.site-manager-login {
  background-image: url("../images//Site-Manager//login-bg.png");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

.manager-sidebar {
  min-height: calc(100vh - 30px);
}

.sidebar-menu-item:hover,
a.sidebar-menu-item.active {
  background-color: #002f55;
  color: white;
}

a.sidebar-menu-item.active,
a.sidebar-menu-item.active * {
  color: #fff !important;
}

.details-tab .ant-tabs-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  border-radius: 5px;
  background: white;
  border: 1px solid #ebebeb;
}

.details-tab .ant-tabs-tab.ant-tabs-tab-active,
.details-tab .ant-tabs-tab-active * {
  background: #002f55;
  color: white !important;
  border: unset !important;
}

.tab-header-btn span {
  color: rgb(156 163 175);
}

.tab-header-btn:hover span,
.tab-header-btn.active span {
  color: #002f55;
}

@media screen and (max-width: 767px) {
  .sitemanager-sidebar {
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100%;
    height: auto;
    min-height: auto;
    padding: 0;
    left: 0;
    bottom: 0;
    border-radius: 0;
    z-index: 1000;
  }

  .sitemanager-dashboard {
    padding-bottom: 150px;
  }

  .header-flex {
    flex-direction: column-reverse;
    gap: 10px;
  }

  .custom-padding-select
    :where(.css-dev-only-do-not-override-1fviqcj).ant-select-single:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    width: 100%;
    height: 40px;
    padding: 3px 11px;
  }

  .ant-tabs-content-holder {
    overflow-x: scroll;
  }

  .sitemanager-dashboard .profile .flex {
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .sitemanager-dashboard .worker-full-details .flex {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .sitemanager-dashboard .profile-details button {
    position: relative;
    right: -30%;
    top: -32px;
  }

  .sitemanager-dashboard .worker-full-details svg {
    display: none;
  }

  .sitemanager-dashboard .profile .ml-3 {
    margin-left: 0 !important;
  }

  /* .ant-tabs-tabpane {
    width: 150%;
  } */
}

.ant-tabs-tab.ant-tabs-tab-active svg * {
  fill: white;
  color: white;
}

.ant-tabs-tab svg * {
  fill: #002f55;
  color: #002f55;
}

/* .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #002f55;
  border-color: #002f55;
}
.ant-checkbox-inner:hover {
  background-color: #002f55;
  border-color: #002f55;
} */

.induction-form-height {
  height: calc(100vh - 120px);
}

.text-capitalize {
  text-transform: capitalize;
}

.text-decoration-underline {
  text-decoration: underline;
}

.hover-effect:hover {
  border: 1px solid rgb(229, 231, 235);
}

.worksite-img > img {
  height: 100px !important;
  width: 100px !important;
  border-radius: 50% !important;
}

.mobile-height {
  height: calc(100vh - 22vh);
}

.ant-popover-inner {
  padding: 0 !important;
}

.marginAuto {
  margin: auto;
}

.ant-tabs-nav-wrap {
  background-color: #f0f4f8;
}

.child-tab .ant-tabs-nav-wrap {
  background-color: #fff;
}

.ant-select-selector {
  height: 42px !important;
  /* border: 1px solid !important; */
  min-width: 140px;
}

.ant-select-selection-placeholder {
  line-height: 42px;
}

.ant-select-selection-search-input {
  height: 42px !important;
}

.ant-select-selection-placeholder {
  line-height: 42px !important;
}

.ant-select-selection-item {
  line-height: 42px !important;
}

.details-tab .ant-tabs-nav-list {
  margin: 5px;
}

.details-tab .ant-tabs-tab {
  padding: 8px 0 !important;
}

.details-tab
  :where(.css-dev-only-do-not-override-1fviqcj).ant-tabs
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin: 0 0 0 13px;
}

.details-tab .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}

.time-picker {
  width: 100%;
}

.clockin-modal .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.clockin-modalmob .ant-modal-footermob {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 200px;
  background-color: #1bbc8d;
}

.absense-timer .ant-btn-link {
  padding: 8px 11px 8px !important;
}
.selectwidth {
  width: 49px !important;
  height: 10px !important;
}
.ant-btn-link {
  color: #000;
}
.mass-opt .ant-select-selector {
  border: 1px solid !important;
}

.mass-opt .ant-select-selection-placeholder {
  color: #000 !important;
}

.mass-opt-date input {
  min-width: 0 !important;
}

.mass-opt-date .ant-picker-suffix {
  color: #000;
}

.mass-opt .ant-select-arrow {
  color: #000;
  margin-right: 5px;
}

.mass-absent-opt.ant-picker {
  width: 100%;
}

.areas-tab .ant-tabs-nav-wrap {
  background-color: transparent;
}

.areas-tab .ant-tabs-nav::before {
  border-bottom: 0;
}

.reason-block {
  background-color: #e8f3ff;
  padding: 10px;
  margin-bottom: 10px;
}

.assign-work .ant-select-selector {
  height: 32px !important;
}

.assign-work .ant-select-selection-item {
  line-height: 32px !important;
}

.termination-modal .ant-modal-body {
  margin-top: 20px;
}

.shift-details.modal {
  z-index: 11111;
}

.ant-select-dropdown {
  z-index: 11111;
}

.asnav-slider .slick-track {
  margin: auto;
}

.confirm-img.modal {
  z-index: 11111;
}

.p-hide {
  visibility: hidden;
}

.payroll.modal {
  z-index: 11111;
}
.smallText {
  font-size: 10px;
}
.bigText {
  font-size: 12x;
}

.zoom-80 {
  zoom: 0.7;
}
@media only screen and (max-width: 767px) {
  .scrollz {
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}

/* Default styles for larger screens */
.flexy {
  display: flex;
  justify-content: space-between;
  width: 100%;
  items-center: center;
  margin-top: 4px;
  margin-bottom: 6px;
}

/* Styles for screens with width less than 767 pixels */
@media (max-width: 767px) {
  .flexy {
    flex-direction: column; /* Display items in a column */
  }
}
